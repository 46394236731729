import React, { Suspense } from 'react';
import Scroll from "react-scroll";
// import MainButtonRegular from '../buttons/main-button-regular';
const scroller = Scroll.scroller;

function Hero({ setOpenDownload }) {

  return (
    <div className="relative overflow-hidden">
      <div className="relative flex justify-center lg:justify-end lg:translate-x-[20vh]">
        <div className="relative flex-shrink-0">
          <img
              src="images/splash-art.webp"
              alt="Dungeon Pals"
              className="sm:max-lg:w-[100vh] h-[60vh] lg:h-[100vh]"
          />
          <div className="sm:max-lg:invisible absolute top-0 left-0 bottom-0 lg:min-w-[600px] bg-gradient-to-r from-darkslate translate-x-[-1px]"></div>
          <div className="lg:invisible absolute left-0 right-0 bottom-0 min-h-[200px]  bg-gradient-to-t from-darkslate"></div>
        </div>
      </div>
      <div className="lg:absolute lg:top-[25%] lg:left-[9%] lg:right-[60%]">

        <div className='flex-row mx-6 my-16 lg:m-0 text-center lg:text-left'>
          <h1 className="font-style-game-title text-white text-5xl lg:text-7xl">
            EXPERIENCE A <span className="text-[#FCBD10]">ROGUELITE</span> GAME WITH YOUR FRIENDS
          </h1>

          <p className="font-style-game text-2xl pt-6 text-white">
            Gain high level of skills to go through with a very steep difficulty curve, then kill the strong final boss!
          </p>

          {/* <div className='flex justify-center lg:justify-start'>
            <a
                  href="https://store.steampowered.com/app/1104450/Bombergrounds_Battle_Royale/"
                  target="_blank"
                  rel="noreferrer"
                  className="group mr-2"
            >
                  <img
                    src="images/steam-download.webp"
                    className="border rounded-md md:h-[50px] h-[auto] mt-8"
                    alt="Steam"
                    width="155"
                    height="50"
                  />
            </a>
          </div> */}

          <p className="font-style-game text-2xl pt-4 text-white">
            Coming soon at the end of 2024.
          </p>
          
          <div className='flex justify-center lg:justify-start'>
            <a
                  href="https://discord.gg/RV2WFttkyD"
                  target="_blank"
                  rel="noreferrer"
                  className="group mr-2"
            >
                  <img
                    src="images/discord-button.webp"
                    className="md:h-[60px] h-[auto] mt-6"
                    alt="Discord"
                    width="180"
                    height="60"
                  />
            </a>
          </div>

        </div>

      </div>
    </div>
  );
}

export default Hero;