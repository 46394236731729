import { useState } from "react";
import { Theme } from "react-daisyui";
import Menu from "./components/menu/menu";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import MonetizationPage from "./containers/monetization";
import HomePage from "./containers/home";

function App() {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Theme dataTheme="dark">
      <Router>
        <div className="App min-h-screen">
          <header className="App-header fixed top-0 left-0 z-10 w-full">
            <Menu toggleOpen={toggleOpen} />
          </header>
          <main className="App-main"> 
            <Routes>
              <Route exact path='/' element={< HomePage />}></Route>  
              {/* <Route exact path='/monetization' element={< MonetizationPage />}></Route>   */}
            </Routes>
          </main>
        </div>
      </Router>
    </Theme>
  );
}

export default App;
