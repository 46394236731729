import React from 'react';
import { Modal } from 'react-daisyui';

function Download({ openDownload, setOpenDownload }) {
  return (
    <Modal
      open={openDownload}
      onClickBackdrop={() => setOpenDownload(false)}
      className="p-0 max-w-[300px] w-[90vw] h-auto"
    >
      <Modal.Body
        className="py-0 px-0 h-full"
      >
        <div className="max-w-xl sm:w-auto mx-auto p-8">
          <a
            href="https://store.steampowered.com/app/1104450/Bombergrounds_Battle_Royale/"
            target="_blank"
            rel="noreferrer"
            className="group block mb-8"
          >
            <img
              src="images/steam-download.webp"
              className="w-full mx-auto relative group-hover:-top-2 top-0 group-hover:drop-shadow-md transition-all rounded-md duration-150 ease-in-out shadow-[0_0_10px_2px] shadow-white"
              alt="Steam"
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/bombergrounds-battle-royale/id1476733308"
            target="_blank"
            rel="noreferrer"
            className="group block mb-8"
          >
            <img
              src="images/appstore-download.webp"
              className="w-full mx-auto relative group-hover:-top-2 top-0 group-hover:drop-shadow-md transition-all rounded-md duration-150 ease-in-out shadow-[0_0_10px_2px] shadow-white"
              alt="App Store"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.Giganticduck.Bombergrounds"
            target="_blank"
            rel="noreferrer"
            className="group block"
          >
            <img
              src="images/playstore-download-new.webp"
              className="w-full mx-auto relative group-hover:-top-2 top-0 group-hover:drop-shadow-md transition-all rounded-md duration-150 ease-in-out shadow-[0_0_10px_2px] shadow-white"
              alt="Play Store"
            />
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Download;