import React, { useState } from 'react';
import ModeSection from './mode-section';

const modes = [
  {
    id: 'battle-royale',
    icon: 'images/modes/icon-mode-battleroyale.webp',
    image: 'images/modes/thumb-mode-battleroyale.webp',
    image_sm: 'images/modes/thumb-mode-battleroyale-sm.webp',
    background: 'images/bg-h.webp',
    name: 'Battle royale',
    description: 'Battle royale, eliminate your foes be the last animal standing!',
    embed: 'https://www.youtube.com/embed/Ue7bc37Nw7o',
  },
  {
    id: 'duck-grab',
    icon: 'images/modes/icon-mode-duckgrab.webp',
    image: 'images/modes/thumb-mode-duckgrab.webp',
    image_sm: 'images/modes/thumb-mode-duckgrab-sm.webp',
    background: 'images/beachland.webp',
    name: 'Duck Grab',
    description: 'Grab the duck and grab the victory, 3v3 capture the objective.',
    embed: 'https://www.youtube.com/embed/RW26NFeHnmY',
  },
  {
    id: 'team-fight',
    icon: 'images/modes/icon-mode-teamfight.webp',
    image: 'images/modes/thumb-mode-teamfight.webp',
    image_sm: 'images/modes/thumb-mode-teamfight-sm.webp',
    background: 'images/candyland.webp',
    name: 'Team Fight',
    description: 'Eliminate the entire enemy team to get the victory.',
    embed: 'https://www.youtube.com/embed/ZWAWNuqzwG8',
  },
  {
    id: 'duel',
    icon: 'images/modes/icon-mode-duel.webp',
    image: 'images/modes/thumb-mode-duel.webp',
    image_sm: 'images/modes/thumb-mode-duel-sm.webp',
    background: 'images/bg-a.webp',
    name: 'Duel',
    description: 'The classic one on one! Defeat your enemy.',
    embed: 'https://www.youtube.com/embed/EhrDZRLuvu4',
  }
];

function Modes() {
  const [currentMode, setCurrentMode] = useState(0);
  
  return (
    <>
      <div className="bg-[url(../public/images/bg-modes.webp)]">
        <div className="container mx-auto sxl:pt-24 pt-16">
          <div className="md:w-3/6 w-11/12 mx-auto md:mb-8 mb-0">
            <h2 className="font-style-game font-medium tracking-wider uppercase md:text-6xl text-4xl text-center text-white">
              Game Modes
            </h2>
          </div>
          <div className="pb-8">
            {
              currentMode !== null && <ModeSection position={currentMode} modes={modes} setCurrentMode={setCurrentMode} />
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Modes;