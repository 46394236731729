import React from "react";

function DiscordNav() {

    return (
        <a
              href="https://discord.gg/RV2WFttkyD"
              target="_blank"
              rel="noreferrer"
        >
            <div className="bg-[#141725] w-[190px] lg:w-[250px] h-[32px] lg:h-[56px] rounded-b-xl lg:rounded-b-2xl absolute right-[8vw] flex justify-center items-center">
                <img 
                    src="images/icon-discord.webp"
                    className="absolute scale-75 lg:scale-100 top-[4px] lg:top-[16px] left-[12px] lg:left-[24px]"
                    alt="Discord"
                    width="24"
                    height="24"
                />
                <p className="font-style-game-small text-white text-sm text-center h-max ml-[20px] lg:ml-[32px] text-[12px] lg:text-[16px]">JOIN OUR COMMUNITY</p>
            </div>
        </a>
    );
}

export default DiscordNav;