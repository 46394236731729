import React, { useState } from 'react';
import { Button } from 'react-daisyui';

function Sidebar({ open }) {
  const [dropdown, setDropdown] = useState(false);

  const toggleDropdown = () => setDropdown(!dropdown);

  return (
    <div className={`fixed md:hidden block w-full pb-[30px] pt-[65px] h-screen bg-black ${open ? 'left-0' : '-left-full'} transition-all ease-in-out duration-500`}>
      <div className="font-inter flex flex-col font-medium tracking-wide">
        <div className="flex justify-between pr-4">
          <a href="https://giganticduck.com/games/" className="block hover:text-white text-[rgba(255,255,255,0.8)] px-[20px] py-[8px] text-[30px] leading-[40px]">
            Games
          </a>
          <Button variant="link" color="white" className="self-center text-white relative right-1 p-0" onClick={toggleDropdown}>
            {
              !dropdown ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16"> <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/> </svg>

              )
            }
          </Button>
        </div>
        <div className={`${dropdown ? 'block' : 'hidden'} p-2`}>
          <a href="https://bombergrounds.com/" className="block hover:text-white text-[rgba(255,255,255,0.8)] text-[1.1em] px-[20px] py-[8px]">
            Bombergrounds - Battle Royale
          </a>
        </div>
        <div>
          <a href="https://giganticduck.com/news/" className="block hover:text-white text-[rgba(255,255,255,0.8)] px-[20px] py-[8px] text-[30px] leading-[40px]">
            News
          </a>
        </div>
        <div>
          <a href="https://giganticduck.com/careers/" className="block hover:text-white text-[rgba(255,255,255,0.8)] px-[20px] py-[8px] text-[30px] leading-[40px]">
            Careers
          </a>
        </div>
        <div>
          {/* <a href="https://giganticduck.com/game-support/" className="block hover:text-white text-[rgba(255,255,255,0.8)] px-[20px] py-[8px] text-[30px] leading-[40px]">
            Support
          </a> */}
          <a href="https://support.giganticduck.com/portal/" className="block hover:text-white text-[rgba(255,255,255,0.8)] px-[20px] py-[8px] text-[30px] leading-[40px]">
            Support
          </a>
        </div>
      </div>
      <div className="font-inter flex flex-col mt-[20px] font-medium tracking-wide text-[18px] leading-[27px]">
        <a href="https://giganticduck.com/contact/" className="hover:text-white text-[rgba(255,255,255,0.8)] px-[20px] py-[8px]">
          Contact
        </a>
        <a href="https://giganticduck.com/investor-relations/" className="hover:text-white text-[rgba(255,255,255,0.8)] px-[20px] py-[8px]">
          Investor Relations
        </a>
        <a href="https://giganticduck.com/privacy-policy/" className="hover:text-white text-[rgba(255,255,255,0.8)] px-[20px] py-[8px]">
          Privacy Policy
        </a>
        <a href="https://giganticduck.com/terms-of-service/" className="hover:text-white text-[rgba(255,255,255,0.8)] px-[20px] py-[8px]">
          Terms of Service
        </a>
      </div>
    </div>
  );
}

export default Sidebar;